define('master-accounts/routes/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    model: function model() {
      return this.get('session.user').request('clients');
    },
    afterModel: function afterModel(model) {
      if (!model.data.length && !this.get('session.user.isAdmin')) {
        return Ember.RSVP.reject({ status: 403 });
      }

      if (model.data.length === 1) {
        return this.transitionTo('show', model.data[0].id);
      }
    }
  });
});