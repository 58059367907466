define('ember-engines/components/link-to-component', ['exports', 'ember-engines/-private/link-to-utils'], function (exports, _linkToUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _LinkComponent$extend;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var LinkComponent = Ember.LinkComponent,
      getOwner = Ember.getOwner,
      get = Ember.get,
      set = Ember.set,
      typeOf = Ember.typeOf,
      assert = Ember.assert;
  exports.default = LinkComponent.extend((_LinkComponent$extend = {}, _defineProperty(_LinkComponent$extend, _linkToUtils.attributeMungingMethod, function () {
    this._super.apply(this, arguments);

    var owner = getOwner(this);

    assert('You attempted to use {{link-to}} within a routeless engine, this is not supported. Use {{link-to-external}} to construct links within a routeless engine. See http://ember-engines.com/guide/linking-and-external-links for more info.', owner.mountPoint !== undefined);

    if (owner.mountPoint) {
      // Prepend engine mount point to targetRouteName
      this._prefixProperty(owner.mountPoint, 'targetRouteName');

      // Prepend engine mount point to current-when if set
      if (get(this, 'current-when') !== null) {
        this._prefixProperty(owner.mountPoint, 'current-when');
      }
    }
  }), _defineProperty(_LinkComponent$extend, '_prefixProperty', function _prefixProperty(prefix, prop) {
    var _this = this;

    var propValue = get(this, prop);

    // Sometimes `targetRouteName` will be a class
    if (typeOf(propValue) !== 'string') {
      return;
    }

    var namespacedPropValue = void 0;
    if (prop === 'current-when') {
      // `current-when` is a space-separated list of routes
      namespacedPropValue = propValue.split(' ');
      namespacedPropValue = namespacedPropValue.map(function (propValue) {
        return _this._namespacePropertyValue(prefix, propValue);
      });
      namespacedPropValue = namespacedPropValue.join(' ');
    } else {
      namespacedPropValue = this._namespacePropertyValue(prefix, propValue);
    }

    set(this, prop, namespacedPropValue);
  }), _defineProperty(_LinkComponent$extend, '_namespacePropertyValue', function _namespacePropertyValue(prefix, propValue) {
    if (propValue === 'application') {
      return prefix;
    } else {
      return prefix + '.' + propValue;
    }
  }), _LinkComponent$extend));
});