define('ember-froala-editor/helpers/merged-hash', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.mergedHash = mergedHash;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function mergedHash(params, hash) {
    // Assumes all params are objects
    if (params.length > 0) {
      return Ember.assign.apply(Ember, [{}].concat(_toConsumableArray(params), [hash]));
    } else {
      return hash;
    }
  } // mergedHash()


  exports.default = Ember.Helper.helper(mergedHash);
});