define('blaze-components/components/blaze-modal', ['exports', 'blaze-components/templates/components/blaze-modal'], function (exports, _blazeModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  // import uniqueId from 'npm:lodash.uniqueid';

  /* global MutationObserver */

  var idCounter = 0;

  function uniqueId() {
    return ++idCounter;
  }

  exports.default = Ember.Component.extend({
    layout: _blazeModal.default,
    tagName: 'span',
    overlayClosesModal: true,
    modalId: Ember.computed(function () {
      return 'blaze-modal-' + uniqueId();
    }),
    modalClasses: 'o-modal',
    document: Ember.inject.service('-document'),
    didInsertElement: function didInsertElement() {
      this.positionModal();
      this.setupRepositionEvents();
    },
    willDestroyElement: function willDestroyElement() {
      if (this.observer) {
        this.observer.disconnect();
      }
    },
    $modal: function $modal() {
      return Ember.$('#' + this.get('modalId'));
    },
    setupRepositionEvents: function setupRepositionEvents() {
      var _this = this;

      Ember.$(window).on('resize', function () {
        return _this.positionModal();
      });

      this.observer = new MutationObserver(function () {
        return _this.positionModal();
      });
      this.observer.observe(this.$modal()[0], {
        attributes: true,
        childList: true,
        subtree: true,
        characterData: true
      });
    },
    positionModal: function positionModal() {
      var $modal = this.$modal();
      var elementWidth = $modal.outerWidth();
      var elementHeight = $modal.outerHeight();

      $modal.css({
        marginLeft: elementWidth * -0.5,
        marginTop: elementHeight * -0.5
      });
    },

    actions: {
      close: function close() {
        if (this.get('overlayClosesModal')) {
          this.sendAction('closeAction');
        }
      }
    }
  });
});