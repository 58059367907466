define('accounting/helpers/format-number', ['exports', 'accounting/format-number'], function (exports, _formatNumber) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params, options) {
    var value = params[0];
    return (0, _formatNumber.default)(value, options);
  });
});