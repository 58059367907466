define('dev/routes', ['exports', 'ember-engines/routes'], function (exports, _routes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _routes.default)(function () {
    this.route('components');
    this.route('icomoon');
    this.route('objects');
    this.route('statuses');
  });
});