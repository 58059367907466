define('master-accounts/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'shared/mixins/authenticated-by-default-route-class-mixin'], function (exports, _applicationRouteMixin, _authenticatedByDefaultRouteClassMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  Ember.Route.reopenClass(_authenticatedByDefaultRouteClassMixin.default);

  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    sessionAuthenticated: function sessionAuthenticated() {}
  });
});