define('ember-asset-loader/errors/load', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function ExtendBuiltin(klass) {
    function ExtendableBuiltin() {
      klass.apply(this, arguments);
    }

    ExtendableBuiltin.prototype = Object.create(klass.prototype);
    ExtendableBuiltin.prototype.constructor = ExtendableBuiltin;
    return ExtendableBuiltin;
  }

  /**
   * A simple Error type to represent an error that occured while loading a
   * resource.
   *
   * @class LoadError
   * @extends Error
   */

  var LoadError = function (_ExtendBuiltin) {
    _inherits(LoadError, _ExtendBuiltin);

    /**
     * Constructs a new LoadError with a supplied error message and an instance
     * of the AssetLoader service to use when retrying a load.
     *
     * @param {String} message
     * @param {AssetLoader} assetLoader
     */
    function LoadError(message, assetLoader) {
      _classCallCheck(this, LoadError);

      var _this = _possibleConstructorReturn(this, (LoadError.__proto__ || Object.getPrototypeOf(LoadError)).apply(this, arguments));

      _this.name = 'LoadError';
      _this.message = message;
      _this.loader = assetLoader;
      return _this;
    }

    /**
     * An abstract hook to define in a sub-class that specifies how to retry
     * loading the errored resource.
     */


    _createClass(LoadError, [{
      key: 'retryLoad',
      value: function retryLoad() {
        throw new Error('You must define a behavior for \'retryLoad\' in a subclass.');
      }
    }, {
      key: '_invokeAndCache',
      value: function _invokeAndCache(method) {
        var _loader;

        for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        return this._retry || (this._retry = (_loader = this.loader)[method].apply(_loader, args));
      }
    }]);

    return LoadError;
  }(ExtendBuiltin(Error));

  exports.default = LoadError;
});