define('ember-number-to-human-size/helpers/number-to-human-size', ['exports', 'ember-number-to-human-size/utils/make-helper'], function (exports, _emberNumberToHumanSizeUtilsMakeHelper) {
  exports.numberToHumanSize = numberToHumanSize;

  function numberToHumanSize(params /*, hash*/) {
    var bytes = params[0];
    var si = !params[1];
    var roundAmount = params[2] || 0;
    var thresh = si ? 1000 : 1024;
    var u = -1;
    var units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);

    return bytes.toFixed(roundAmount) + ' ' + units[u];
  }

  exports['default'] = (0, _emberNumberToHumanSizeUtilsMakeHelper['default'])(numberToHumanSize);
});