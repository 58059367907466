define('clients/routes', ['exports', 'ember-engines/routes'], function (exports, _routes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _routes.default)(function () {
    this.route('login');
    this.route('create');
    this.route('show', { path: ':id' }, function () {
      this.route('organizations');
      this.route('users');
      this.route('transfer-credits');
      this.route('edit');
    });
  });
});