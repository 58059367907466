define('blaze-components/services/validator', ['exports', 'npm:validatorjs'], function (exports, _npmValidatorjs) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  exports.default = Ember.Service.extend({
    i18n: Ember.inject.service(),
    make: function make(data, rules) {
      var attributes = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      var validator = new _npmValidatorjs.default(data, rules);

      if (attributes) {
        validator.setAttributeNames(attributes);
      }

      return validator;
    },
    errorsFromServer: function errorsFromServer(response, attributes) {
      var rules = {};
      if (Ember.get(response, 'payload.errors')) {
        Object.keys(response.payload.errors).forEach(function (key) {
          var serverRule = response.payload.errors[key][0];

          var _serverRule$split = serverRule.split(';'),
              _serverRule$split2 = _toArray(_serverRule$split),
              rule = _serverRule$split2[0],
              serverParams = _serverRule$split2.slice(1);

          if (!rule.startsWith('validation.')) {
            return;
          }

          var params = {};
          if (serverParams) {
            serverParams.forEach(function (param) {
              var split = param.split(':');
              params[split[0]] = split[1];
            });
          }

          rule = rule.split('.')[1];
          if (params[rule]) {
            rule += ':' + params[rule];
          }

          rules[key] = rule;
        });
      }

      var validator = this.make({}, rules, attributes);

      if (Ember.get(response, 'payload.data.error.code') === 'InvalidCurrentPassword') {
        validator.errors.add('password', this.get('i18n').t('validation.incorrect_password'));
      }

      if (!Ember.get(response, 'payload.errors') || response.payload.status !== 422) {
        return validator;
      }

      Object.keys(rules).forEach(function (field) {
        var _rules$field$split = rules[field].split(':'),
            _rules$field$split2 = _slicedToArray(_rules$field$split, 2),
            name = _rules$field$split2[0],
            value = _rules$field$split2[1];

        var rule = validator.getRule(name);
        rule._setValidatingData(field, name, value);
        validator.errors.add(field, validator.messages.render(rule));
      });

      return validator;
    }
  });
});