define('ember-engines/-private/engine-ext', ['ember-engines/components/link-to-component', 'ember-engines/components/link-to-external-component', 'ember-engines/-private/ext-require'], function (_linkToComponent, _linkToExternalComponent, _extRequire) {
  'use strict';

  var Engine = (0, _extRequire.default)('ember-application/system/engine');

  Engine.reopen({
    buildRegistry: function buildRegistry() {
      var registry = this._super.apply(this, arguments);

      if (!(this instanceof Ember.Application)) {
        registry.register('component:link-to', _linkToComponent.default);
        registry.register('component:link-to-external', _linkToExternalComponent.default);
      }

      return registry;
    }
  });
});