define('shared/helpers/phrase', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.paramsToLocaleKey = paramsToLocaleKey;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function paramsToLocaleKey(i18n, params) {
    var global = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

    return params.map(function (key) {
      if (Ember.typeOf(key) === 'instance') {
        var modulePrefix = Ember.get(key, 'namespace.modulePrefix');
        var componentPath = Ember.get(key, 'layout.meta.moduleName');

        if (global) {
          if (modulePrefix === 'postedin' || global === 'app') {
            return 'app:';
          }

          if (modulePrefix) {
            return 'lib/' + modulePrefix + ':';
          }

          if (componentPath) {
            var path = componentPath.split('/')[0];

            return 'lib/' + path + ':';
          }

          return 'app:';
        }

        if (componentPath) {
          return 'lib/' + componentPath.replace('/templates', '/addon/templates').replace('.hbs', '') + ':';
        }

        var prefix = 'app/';
        if (modulePrefix && modulePrefix !== 'postedin') {
          prefix = 'lib/' + modulePrefix + '/addon/';
        }

        if (key.toString().startsWith('(generated ')) {
          return prefix + 'templates/' + key.toString().split(' ')[1].replace(/\./g, '/') + ':';
        }

        var translations = Ember.get(key, 'translations');
        if (translations && !Ember.get(key, 'translationsAdded')) {
          Ember.set(key, 'translationsAdded', true);
          Object.keys(translations).forEach(function (locale) {
            var newTranslations = {};
            Object.keys(translations[locale]).forEach(function (translation) {
              newTranslations[paramsToLocaleKey(i18n, [key, translation])] = translations[locale][translation];
            });

            i18n.addTranslations(locale, newTranslations);
          });
        }

        var parse = key.toString().split('@');
        if (parse.length === 2) {
          parse = parse[1].split(':');

          if (parse[0] === 'controller') {
            return prefix + 'templates/' + parse[1].replace(/\./g, '/') + ':';
          }

          if (parse.length > 1) {
            return prefix + 'templates/' + parse[0] + 's/' + parse[1].replace(/\./g, '/') + ':';
          }
        }

        return key.toString() + ':';
      }

      return key;
    }).join('').replace(/^\.+|\.+$/g, '');
  }

  function isRaw(keys) {
    var stringKeys = keys.filter(function (k) {
      return typeof k === 'string';
    });

    if (stringKeys.length && !stringKeys[0].startsWith('phrase:')) {
      return true;
    }

    return false;
  }

  exports.default = Ember.Helper.extend({
    i18n: Ember.inject.service(),
    compute: function compute(params, options) {
      // if dynamic and the first string doesn't start with `phrase:` then treat it raw
      if (options.dynamic && isRaw(params)) {
        return params.filter(function (s) {
          return (typeof s === 'undefined' ? 'undefined' : _typeof(s)) !== 'object';
        }).join('');
      }

      return this.get('i18n').t(paramsToLocaleKey(this.get('i18n'), params, options.global), options);
    },

    localeDidChange: Ember.observer('i18n.locale', function () {
      this.recompute();
    })
  });
});