define('ember-cli-simple-store/store', ['exports', 'ember-cli-simple-store/models/record-proxy', 'ember-cli-simple-store/models/record-array', 'ember-cli-simple-store/models/filtered-record-array'], function (exports, _recordProxy, _recordArray, _filteredRecordArray) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    function buildRecord(type, data, store) {
        var record = createRecord(type, data, store);
        arrayForType(type, store).pushObject(record);

        return record;
    }

    function createRecord(type, data, store) {
        var factory = factoryForType(type, store);
        var primaryKey = primaryKeyForType(type, store);

        (true && !(factory) && Ember.assert("No model was found for type: " + type, factory));


        var record = factory.create(data);
        var id = data[primaryKey];
        identityMapForType(type, store)[id] = record;

        return record;
    }

    function factoryForType(type, store) {
        return Ember.getOwner(store).factoryFor("model:" + type);
    }

    function primaryKeyForType(type, store) {
        var factory = factoryForType(type, store) || {};
        // http://emberjs.com/deprecations/v2.x/#toc_migrating-from-_lookupfactory-to-factoryfor
        return factory.class && (factory.class.primaryKey || 'id');
    }

    function arrayForType(type, store) {
        var all = store.get("array");
        var models = all[type] || [];
        all[type] = models;
        return Ember.A(models);
    }

    function identityMapForType(type, store) {
        var typeIdentityMap = store.get("identityMap");
        var idIdentityMap = typeIdentityMap[type] || {};
        typeIdentityMap[type] = idIdentityMap;

        return idIdentityMap;
    }

    var ServiceType = Ember.Service || Ember.Object;

    var Store = ServiceType.extend({
        init: function init() {
            this._super.apply(this, arguments);
            this.reset();
        },
        reset: function reset() {
            var _this = this;

            this.set("recompute", Ember.A());
            this.set("filtersMap", {});
            this.set("identityMap", {});

            var array = this.get("array") || {};
            Object.keys(array).forEach(function (type) {
                arrayForType(type, _this).forEach(function (record) {
                    return record.destroy();
                });
            });
            this.set("array", {});
        },
        willDestroy: function willDestroy() {
            this.setProperties({
                "array": null,
                "identityMap": null,
                "filtersMap": null,
                "recompute": null
            });
        },
        clear: function clear(type) {
            if (type === undefined) {
                this.reset();
            }

            delete this.get("identityMap")[type];
            var records = arrayForType(type, this);
            records.forEach(function (record) {
                return record.destroy();
            });
            records.clear();
            this.scheduleUpdate(type);
        },
        remove: function remove(type, id) {
            var record = this._findById(type, id);
            if (record) {
                var primaryKey = primaryKeyForType(type, this);
                delete this.get("identityMap")[type][record[primaryKey]];
                arrayForType(type, this).removeObject(record);
                record.destroy();
                this.scheduleUpdate(type);
            }
        },
        push: function push(type, data) {
            var primaryKey = primaryKeyForType(type, this);
            data[primaryKey] = this._coerceId(data[primaryKey]);
            var record = this._findById(type, data[primaryKey]);

            if (record) {
                Ember.setProperties(record, data);
            } else {
                record = buildRecord(type, data, this);
            }

            this.scheduleUpdate(type);

            return record;
        },
        pushArray: function pushArray(type, dataArray) {
            var _this2 = this;

            var primaryKey = primaryKeyForType(type, this);
            var records = [];

            dataArray.forEach(function (data) {
                data[primaryKey] = _this2._coerceId(data[primaryKey]);
                var record = _this2._findById(type, data[primaryKey]);

                if (record) {
                    Ember.setProperties(record, data);
                } else {
                    record = createRecord(type, data, _this2);
                    records.push(record);
                }
            });

            arrayForType(type, this).pushObjects(records);

            this.scheduleUpdate(type);

            return records;
        },
        scheduleUpdate: function scheduleUpdate(type) {
            var recompute = this.get("recompute");
            recompute.addObject(type);
            Ember.run.scheduleOnce('actions', this, 'updateFilters');
        },
        updateFilters: function updateFilters() {
            if (this.get('isDestroyed') || this.get('isDestroying')) {
                return;
            }
            var recompute = this.get("recompute");
            var filtersMap = this.get("filtersMap");

            Object.keys(filtersMap).forEach(function (type) {
                var filters = filtersMap[type] || [];

                filters.forEach(function (recordArray) {
                    if (recompute.includes(type)) {
                        var updatedContent = recordArray.updateContent();
                        recordArray.set("content", updatedContent);
                    }
                });
            });

            this.set("recompute", Ember.A());
        },
        _unsubscribe: function _unsubscribe() {
            var _this3 = this;

            var updatedFiltersMap = void 0;

            for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
                args[_key] = arguments[_key];
            }

            var filterIds = Ember.A(args.map(function (func) {
                var primaryKey = primaryKeyForType(func.type, _this3);
                return func[primaryKey];
            }));
            var filtersMap = this.get("filtersMap");
            Object.keys(filtersMap).forEach(function (type) {
                var primaryKey = primaryKeyForType(type, _this3);
                var filters = filtersMap[type] || [];
                updatedFiltersMap = filters.filter(function (func) {
                    return !filterIds.includes(func[primaryKey]);
                });
                filtersMap[type] = updatedFiltersMap;
            });
        },
        find: function find(type, options) {
            if (typeof options === "undefined") {
                return this._findAllProxy(type);
            }

            if (options instanceof Function) {
                return this._findWithFilterFunc(type, options);
            }

            if ((typeof options === 'undefined' ? 'undefined' : _typeof(options)) === "object") {
                var params = Object.keys(options);

                (true && !(params.length) && Ember.assert("No key was found in the filter options", params.length));


                var attr = params[0];
                var value = options[attr];

                return this._findWithFilterFunc(type, function filterFunction(m) {
                    return Ember.get(m, attr) === value;
                });
            }

            return this._findByIdComputed(type, options);
        },
        findOne: function findOne(type) {
            return _recordProxy.default.create({
                _store: this,
                _type: type,
                _source: this._findAll(type),
                compute: function compute() {
                    return this.get("_source").objectAt(0);
                }
            });
        },
        _findById: function _findById(type, id) {
            var identityMap = identityMapForType(type, this);
            return identityMap[id] || null;
        },
        _findAll: function _findAll(type) {
            return arrayForType(type, this);
        },
        _findAllProxy: function _findAllProxy(type) {
            return _recordArray.default.create({
                _type: type,
                _store: this,
                _source: this._findAll(type)
            });
        },
        _findWithFilterFunc: function _findWithFilterFunc(type, filter_func) {
            var func = _filteredRecordArray.default.create({
                _type: type,
                _store: this,
                _id: Ember.uuid(),
                _filter_func: filter_func,
                _source: this._findAll(type)
            });
            var filtersMap = this.get("filtersMap");
            var filters = filtersMap[type] || [];
            filters.push(func);
            filtersMap[type] = filters;
            return func;
        },
        _coerceId: function _coerceId(id) {
            var numberId = Number(id);
            if (!isNaN(numberId) && numberId.toString().length === id.toString().length) {
                return numberId;
            }
            return id;
        },
        _findByIdComputed: function _findByIdComputed(type, id) {
            var actualId = this._coerceId(id);
            var primaryKey = primaryKeyForType(type, this);

            return _recordProxy.default.create({
                _store: this,
                _type: type,
                _filter_value: actualId,
                _source: this._findAll(type),
                compute: function compute() {
                    var filter_value = this.get("_filter_value");
                    return this.get("_source").findBy(primaryKey, filter_value);
                }
            });
        }
    });

    exports.default = Store;
});