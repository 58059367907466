define('ember-engines/initializers/engines', ['exports', 'ember-engines/-private/route-ext', 'ember-engines/-private/router-ext', 'ember-engines/-private/engine-ext', 'ember-engines/-private/engine-instance-ext'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  // TODO: Move to ensure they run prior to instantiating Ember.Application
  // Load extensions to Ember
  function initialize() {}

  exports.default = {
    name: 'engines',
    initialize: initialize
  };
});