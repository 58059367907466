define('ember-cli-simple-store/models/record-proxy', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.ObjectProxy.extend({
        _source: null,
        _store: null,
        _type: null,

        content: Ember.computed("_source.[]", function () {
            return this.compute();
        }),

        init: function init() {
            this._super.apply(this, arguments);
            var store = this.get('_store');
            var type = this.get('_type');

            var model = Ember.getOwner(store).lookup('model:' + type);

            for (var method in model) {
                if (typeof model[method] === "function") {
                    if (!this[method]) {
                        this.proxyMethod(method);
                    }
                }
            }
        },
        compute: function compute() {},
        proxyMethod: function proxyMethod(method) {
            this[method] = function () {
                var content = this.get("content");
                return content[method].apply(content, arguments);
            };
        }
    });
});