define('ember-engines/-private/route-ext', [], function () {
  'use strict';

  var Route = Ember.Route,
      getOwner = Ember.getOwner;


  /*
    Creates an aliased form of a method that properly resolves external routes.
  */
  function externalAlias(methodName) {
    return function _externalAliasMethod(routeName) {
      var _router;

      var externalRoute = getOwner(this)._getExternalRoute(routeName);

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      return (_router = this.router)[methodName].apply(_router, [externalRoute].concat(args));
    };
  }

  Route.reopen({
    transitionToExternal: externalAlias('transitionTo'),
    replaceWithExternal: externalAlias('replaceWith')
  });
});