define('credits/helpers/credits', ['exports', 'accounting/format-money'], function (exports, _formatMoney) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.credits = credits;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function credits(amount) {
    var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

    var space = type === 'compact' ? '' : ' ';
    var html = type !== 'plain';

    var options = {
      symbol: '',
      precision: 0,
      format: {
        pos: '+%v',
        neg: '-%v',
        zero: '%v'
      }
    };

    if (!html) {
      return (0, _formatMoney.default)(amount, options);
    }

    options.format = {
      pos: '<ins>+</ins>' + space + '<span>%v</span>',
      neg: '<del>-</del>' + space + '<span>%v</span>',
      zero: '<span>%v</span>'
    };

    return '<span class="u-credits">' + (0, _formatMoney.default)(amount, options) + '</span>';
  }

  exports.default = Ember.Helper.helper(function (_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 1),
        amount = _ref3[0];

    var type = _ref2.type;

    return Ember.String.htmlSafe(credits(amount, type));
  });
});