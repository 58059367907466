define('ember-engines/components/link-to-external-component', ['exports', 'ember-engines/-private/link-to-utils'], function (exports, _linkToUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var LinkComponent = Ember.LinkComponent,
      getOwner = Ember.getOwner,
      get = Ember.get,
      set = Ember.set;
  exports.default = LinkComponent.extend(_defineProperty({}, _linkToUtils.attributeMungingMethod, function () {
    this._super.apply(this, arguments);

    var owner = getOwner(this);

    if (owner.mountPoint) {
      var targetRouteName = get(this, 'targetRouteName');
      var externalRoute = owner._getExternalRoute(targetRouteName);
      set(this, 'targetRouteName', externalRoute);
    }
  }));
});