define('content-suggestion-components/components/content-suggestion-form-modal', ['exports', 'content-suggestion-components/templates/components/content-suggestion-form-modal'], function (exports, _contentSuggestionFormModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _contentSuggestionFormModal.default,
    keywords: [],
    projectDidChange: Ember.on('init', Ember.observer('project', function () {
      var _this = this;

      this.set('keywords', []);
      if (this.get('project')) {
        this.get('project').request('profile').then(function (profile) {
          _this.set('keywords', profile.get('keywords'));
        });
      }
    })),
    actions: {
      suggestionSaved: function suggestionSaved(suggestion, redirect) {
        if (redirect && this.closeAction) {
          this.closeAction();
        }

        if (this.onSuggestionSaved) {
          this.onSuggestionSaved(suggestion, redirect);
        }
      }
    }
  });
});