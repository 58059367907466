define("ember-cli-focus-input/components/focus-button", ["exports", "ember"], function (exports, _ember) {

    var FocusButton = _ember["default"].Component.extend({
        tagName: "button",
        attributeBindings: ["type"],
        didInsertElement: function didInsertElement() {
            this.$().focus();
        }
    });

    exports["default"] = FocusButton;
});