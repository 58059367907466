enifed('ember-babel', ['exports'], function (exports) {
  'use strict';

  exports.inherits = function (subClass, superClass) {
    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });

    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : defaults(subClass, superClass);
  };
  exports.taggedTemplateLiteralLoose = function (strings, raw) {
    strings.raw = raw;
    return strings;
  };
  exports.createClass = function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
  exports.defaults = defaults;


  function defineProperties(target, props) {
    var i, descriptor;

    for (i = 0; i < props.length; i++) {
      descriptor = props[i];

      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ('value' in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  function defaults(obj, defaults) {
    var keys = Object.getOwnPropertyNames(defaults),
        i,
        key,
        value;
    for (i = 0; i < keys.length; i++) {
      key = keys[i];
      value = Object.getOwnPropertyDescriptor(defaults, key);

      if (value && value.configurable && obj[key] === undefined) {
        Object.defineProperty(obj, key, value);
      }
    }
    return obj;
  }

  exports.possibleConstructorReturn = function (self, call) {
    return call && (typeof call === 'object' || typeof call === 'function') ? call : self;
  };

  exports.slice = Array.prototype.slice;
});