define('ember-asset-loader/errors/asset-load', ['exports', 'ember-asset-loader/errors/load', 'ember-asset-loader/services/asset-loader'], function (exports, _load, _assetLoader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var AssetLoadError = function (_LoadError) {
    _inherits(AssetLoadError, _LoadError);

    /**
     * Constructs a new AssetLoadError from the original error and the info of the
     * asset that was attempting to load.
     *
     * @param {AssetLoader} assetLoader
     * @param {Asset} asset
     * @param {Error} error
     */
    function AssetLoadError(assetLoader, asset, error) {
      _classCallCheck(this, AssetLoadError);

      var _this = _possibleConstructorReturn(this, (AssetLoadError.__proto__ || Object.getPrototypeOf(AssetLoadError)).call(this, 'The ' + asset.type + ' asset with uri "' + asset.uri + '" failed to load with the error: ' + error + '.', assetLoader));

      _this.name = 'AssetLoadError';
      _this.asset = asset;
      _this.originalError = error;
      return _this;
    }

    _createClass(AssetLoadError, [{
      key: 'retryLoad',
      value: function retryLoad() {
        return this._invokeAndCache('loadAsset', this.asset, _assetLoader.RETRY_LOAD_SECRET);
      }
    }]);

    return AssetLoadError;
  }(_load.default);

  exports.default = AssetLoadError;
});