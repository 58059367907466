define('ember-froala-editor/helpers/froala-method', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.froalaMethod = froalaMethod;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _toArray(arr) {
    return Array.isArray(arr) ? arr : Array.from(arr);
  }

  function froalaMethod(_ref, helperHash) {
    var _ref2 = _toArray(_ref),
        methodName = _ref2[0],
        helperParams = _ref2.slice(1);

    // Create a closure to pass back as the "action" to be triggered
    return function froalaMethodClosure(component) {

      // The helper hash can be used to "replace" the param
      // values with params received from the event...
      for (var propName in helperHash) {

        // Determine the positions within each params arrays
        // which should be used for replacing. parseInt() in
        // case the value is a string integer, ex: "2".
        var helperPos = helperParams.indexOf(propName);
        var eventPos = parseInt(helperHash[propName]);

        // If the parameter "name" was found in the helperParams,
        // and the relative arguments contains something at that position,
        // then go ahead and replace the param value...
        if (helperPos !== -1 && arguments.length >= eventPos) {
          helperParams[helperPos] = arguments[eventPos - 1];
        }
      } // for ()


      // Trigger the Froala Editor method via the Ember Component action
      component.send.apply(component, ['method', methodName].concat(_toConsumableArray(helperParams)));
    }; // froalaMethodClosure()
  } // froalaMethod()


  exports.default = Ember.Helper.helper(froalaMethod);
});