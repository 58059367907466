define('npm:@soxhub/htmldiff', function(){ return { 'default': require('@soxhub/htmldiff')};})
define('npm:component-emitter', function(){ return { 'default': require('component-emitter')};})
define('npm:csv-stringify', function(){ return { 'default': require('csv-stringify')};})
define('npm:highlight.js', function(){ return { 'default': require('highlight.js')};})
define('npm:js-beautify', function(){ return { 'default': require('js-beautify')};})
define('npm:laravel-echo', function(){ return { 'default': require('laravel-echo')};})
define('npm:lodash.escaperegexp', function(){ return { 'default': require('lodash.escaperegexp')};})
define('npm:lodash.mapvalues', function(){ return { 'default': require('lodash.mapvalues')};})
define('npm:pusher-js', function(){ return { 'default': require('pusher-js')};})
define('npm:query-string', function(){ return { 'default': require('query-string')};})
define('npm:rangy', function(){ return { 'default': require('rangy')};})
define('npm:speakingurl', function(){ return { 'default': require('speakingurl')};})
define('npm:tether', function(){ return { 'default': require('tether')};})
define('npm:validatorjs', function(){ return { 'default': require('validatorjs')};})