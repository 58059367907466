define('ember-cli-bugsnag/utils/errors', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getContext = getContext;
  exports.generateError = generateError;
  function getContext(router) {
    var infos = router.currentState.routerJsState.handlerInfos;

    var url = router.get('location').getURL();
    var routeName = infos[infos.length - 1].name;

    var firstSegments = routeName.replace(".index", "").replace(/\./g, ' ');
    var prettyRouteName = Ember.String.capitalize(firstSegments);

    return prettyRouteName + " (" + routeName + ", " + url + ")";
  }

  function generateError(cause, stack) {
    var error = new Error(cause);
    error.stack = stack;
    return error;
  }
});