define('shared/mixins/authenticated-by-default-route-class-mixin', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _applicationRouteMixin, _authenticatedRouteMixin, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    create: function create() {
      var route = this._super.apply(this, arguments);

      var simpleAuthRouteMixinApplied = _applicationRouteMixin.default.detect(route) || _authenticatedRouteMixin.default.detect(route) || _unauthenticatedRouteMixin.default.detect(route);

      // override if explicit
      if (simpleAuthRouteMixinApplied) {
        return route;
      }

      var _EmberGetProperties = Ember.getProperties(route, ['needsGuest', 'needsAuth']),
          needsGuest = _EmberGetProperties.needsGuest,
          needsAuth = _EmberGetProperties.needsAuth;

      // defaults to needsAuth, to set the route to "open" you set `needsGuest` and `needsAuth` to `false`


      if (needsAuth === undefined && !needsGuest) {
        needsAuth = true;
      }

      if (needsGuest && !_unauthenticatedRouteMixin.default.detect(route)) {
        _unauthenticatedRouteMixin.default.apply(route);
      }

      if (needsAuth && !_authenticatedRouteMixin.default.detect(route)) {
        _authenticatedRouteMixin.default.apply(route);
      }

      return route;
    }
  });
});