define('ember-engines/-private/link-to-utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = hasEmberVersion;


  // LAME, but ¯\_(ツ)_/¯
  function hasEmberVersion(major, minor) {
    var numbers = Ember.VERSION.split('-')[0].split('.');
    var actualMajor = parseInt(numbers[0], 10);
    var actualMinor = parseInt(numbers[1], 10);
    return actualMajor > major || actualMajor === major && actualMinor >= minor;
  }

  var attributeMungingMethod = exports.attributeMungingMethod = function () {
    if (hasEmberVersion(2, 10)) {
      return 'didReceiveAttrs';
    } else {
      return 'willRender';
    }
  }();
});