define('ember-asset-loader/loaders/js', ['exports', 'ember-asset-loader/loaders/utilities'], function (exports, _utilities) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _utilities.nodeLoader)(function js(uri) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      if (document.querySelector('script[src="' + uri + '"]')) {
        return resolve();
      }

      var script = (0, _utilities.createLoadElement)('script', resolve, function (error) {
        if (this.parentNode) {
          this.parentNode.removeChild(this);
        }
        reject(error);
      });

      script.src = uri;
      script.async = false;

      document.head.appendChild(script);
    });
  });
});