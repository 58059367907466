define('ember-asset-loader/loaders/css', ['exports', 'ember-asset-loader/loaders/utilities'], function (exports, _utilities) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _utilities.nodeLoader)(function css(uri) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      if (document.querySelector('link[href="' + uri + '"]')) {
        return resolve();
      }

      // Try using the default onload/onerror handlers...
      var link = (0, _utilities.createLoadElement)('link', resolve, function (error) {
        if (this.parentNode) {
          this.parentNode.removeChild(this);
        }
        reject(error);
      });

      link.rel = 'stylesheet';
      link.href = uri;

      document.head.appendChild(link);

      // In case the browser doesn't fire onload/onerror events, we poll the
      // the list of stylesheets to see when it loads...
      function checkSheetLoad() {
        var resolvedHref = link.href;
        var stylesheets = document.styleSheets;
        var i = stylesheets.length;

        while (i--) {
          var sheet = stylesheets[i];
          if (sheet.href === resolvedHref) {
            // Unfortunately we have no way of knowing if the load was
            // successful or not, so we always resolve.
            setTimeout(resolve);
            return;
          }
        }

        setTimeout(checkSheetLoad);
      }

      setTimeout(checkSheetLoad);
    });
  });
});