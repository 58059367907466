define('shared/components/task-error', ['exports', 'shared/templates/components/task-error'], function (exports, _taskError) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _taskError.default,
    isSave: false,
    actions: {
      retry: function retry() {
        if (this.get('isSave')) {
          this.set('retry', true);
        } else {
          this.get('task').perform();
        }
      }
    }
  });
});