define('ember-froala-editor/components/froala-editor', ['exports', 'ember-froala-editor/templates/components/froala-editor'], function (exports, _froalaEditor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var FroalaEditorComponent = Ember.Component.extend({
    layout: _froalaEditor.default,
    classNames: ['froala-editor-container'],

    // Enable the ability to .extend() this Component
    // to define "default" options that will get merged
    // with any options passed in as an attribute
    mergedProperties: ['defaultOptions', 'options'],

    // jQuery selector to find the froala-editor container
    // Note: Using a nested `<div>` to prevent conflicts
    //       between ember and froala-editor for DOM changes
    editorSelector: 'div.froala-editor-instance',

    // Froala Editor prefixes all event names,
    // which is technically NOT a namespace,
    // and should be used when attaching event handlers
    // Note: Include the trailing dot
    eventPrefix: 'froalaEditor.',

    // Froala Editor event name that will trigger the
    // `update` action, mainly used to update the `content` value
    // and takes a cue from ember-one-way-controls
    updateEvent: 'contentChanged',

    // Option to return a SafeString when using on-*-getHtml event actions
    // By default, look at the current type of `content`
    returnSafeString: Ember.computed('content', {
      get: function get() {
        return Ember.String.isHTMLSafe(this.get('content'));
      }
    } // get()
    ), update: function update() {
      Ember.deprecate("froala-editor 'content' will no longer be updated by the component (two way bound), instead use the 'update' event action to '(mut)' the original property", this.get('_updateActionWarned'), {
        id: 'ember-froala-editor.updateAction',
        until: '2.6.0',
        url: 'https://github.com/froala/ember-froala-editor/releases/tag/2.4.0'
      });
      this.set('_updateActionWarned', true);
    },

    contentBindingEvent: Ember.computed.deprecatingAlias('updateEvent', {
      id: 'ember-froala-editor.contentBindingEvent',
      until: '2.6.0',
      url: 'https://github.com/froala/ember-froala-editor/releases/tag/2.4.0'
    }),
    isSafeString: Ember.computed.deprecatingAlias('returnSafeString', {
      id: 'ember-froala-editor.isSafeString',
      until: '2.6.0',
      url: 'https://github.com/froala/ember-froala-editor/releases/tag/2.4.0'
    }),
    _optionsChanged: Ember.computed('options', {
      get: function get() {
        // Skip the first "get" from the `init()` hook
        if (!this.get('_optionsChangedFirst')) {
          this.set('_optionsChangedFirst', true);
        } else {
          Ember.deprecate("froala-editor 'options' changed post-initialization no longer updates the editor, instead use the related froala-editor methods", this.get('_optionsChangedWarned'), {
            id: 'ember-froala-editor.optionsChanged',
            until: '2.6.0',
            url: 'https://github.com/froala/ember-froala-editor/releases/tag/2.4.0'
          });
          this.set('_optionsChangedWarned', true);
        }
      }
    }),
    _defaultOptions: { warned: false }, // Objects are shared across all instances


    // Private, internal Computed Property to handle SafeString support
    // and it will always return a string, even if `content` is null or undefined
    // Note: Both Strings and SafeStrings have a .toString() function
    _content: Ember.computed('content', {
      get: function get() {
        var content = this.get('content');
        return content && typeof content.toString === 'function' ? content.toString() : '';
      }
    } // get()
    ), // :_content


    // Private, internal Computed Property to merge all the possible "options"
    _options: Ember.computed('defaultOptions', 'options', '_attributeOptions', {
      get: function get() {
        var config = Ember.getOwner(this).resolveRegistration('config:environment');
        return Ember.assign({}, Ember.getWithDefault(config, 'ember-froala-editor', {}), this.getWithDefault('defaultOptions', {}), this.getWithDefault('options', {}), this.get('_attributeOptions'));
      }
    } // get()
    ), // :_options


    // Private, internal Computed Property to gather all the Froala Editor options
    // that are set as individual attributes. Ex: `{{froala-editor theme="red"}}`
    _attributeOptions: Ember.computed({
      get: function get() {
        var attributeOptions = {};

        // Note: Use a for-in loop here over Object.keys() to get _all_ properties
        //       up the proto-chain, specifically needed when .extend()ing
        for (var propertyName in this) {

          // Verify that the property name aligns with a Froala Editor option name
          if (Ember.$.FroalaEditor.DEFAULTS.hasOwnProperty(propertyName)) {
            attributeOptions[propertyName] = this.get(propertyName);
          }
        } // for ()

        return attributeOptions;
      }
    } // get()
    ), // :_attributeOptions


    // Public facing API's for editor instance and state information
    // Note: The referenced properties are .set() on the init() hook
    editor: Ember.computed.readOnly('_editor'),
    editorInitializing: Ember.computed.readOnly('_editorInitializing'),
    editorInitialized: Ember.computed.readOnly('_editorInitialized'),
    editorDestroying: Ember.computed.readOnly('_editorDestroying'),
    editorDestroyed: Ember.computed.not('editorInitialized'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('_editor', null);
      this.set('_editorInitializing', false);
      this.set('_editorInitialized', false);
      this.set('_editorDestroying', false);
      this.get('_optionsChanged'); // To monitor changes for depreciation notices
      if (typeof this.get('defaultOptions') !== 'undefined') {
        Ember.deprecate("froala-editor 'defaultOptions' has been deprecated, use 'options' instead when .extend()ing the froala-editor component", this.get('_defaultOptions.warned'), // only warn once
        {
          id: 'ember-froala-editor.defaultOptions',
          until: '2.6.0',
          url: 'https://github.com/froala/ember-froala-editor/releases/tag/v2.5.0'
        });
        this.set('_defaultOptions.warned', true); // only warn once
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.run.schedule('afterRender', this, this.initEditor);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var editor = this.get('_editor');
      var content = this.get('_content');

      if (editor && content !== editor.html.get()) {
        editor.html.set(content);
      } else if (!editor && content !== this.$(this.get('editorSelector')).html()) {
        // Note: Must use jQuery! Updating a bound template property causes the following error,
        //       which is likely caused by the way froala-editor modifies DOM and Glimmer not liking that..
        // Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.
        this.$(this.get('editorSelector')).html(content);
      } else {
        // Note: _attributeOptions will only re-compute if editor is reinit'ed
        this.notifyPropertyChange('_attributeOptions');
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.destroyEditor();
    },
    initEditor: function initEditor() {

      // Do NOT re-initialize, user should call reinitEditor()
      if (this.get('_editorInitialized') || this.get('_editorInitializing')) {
        return;
      }

      // Update the state flag so we know initialization has started
      this.set('_editorInitializing', true);

      // Get the complete set of options to be used
      var options = this.get('_options');

      // Init jQuery once...
      var $element = this.$(this.get('editorSelector'));

      // Attach a one time Froala Editor initialization event handler
      // to know when initialization has finished, updating state flags
      // Note: Cannot be done via editor.events.on()
      //       since access to `editor` is not available yet
      $element.one(this.get('eventPrefix') + (options.initOnClick ? 'initializationDelayed' : 'initialized'), Ember.run.bind(this, 'didInitEditor'));

      // Actual initialization of the Froala Editor
      $element.froalaEditor(options);
    },
    reinitEditor: function reinitEditor() {
      this.destroyEditor();
      this.initEditor();
    },
    destroyEditor: function destroyEditor() {

      // Do NOT try to destroy if there is nothing to destroy...
      // Note: !this.get('_editorInitialized') == this.get('editorDestroyed')
      if (!this.get('_editorInitialized') || this.get('_editorDestroying')) {
        return;
      }

      // Update the state flag so we know destroying has started
      this.set('_editorDestroying', true);

      // Actual destruction of the Froala Editor
      this.$(this.get('editorSelector')).froalaEditor('destroy');
    },
    didInitEditor: function didInitEditor(event, editor) {
      this.set('_editorInitializing', false);
      this.set('_editorInitialized', true);
      this.set('_editor', editor);

      // Determine which initialization event was used
      var initEventPropName = this.get('_options.initOnClick') ? 'on-initializationDelayed' : 'on-initialized';

      // Regex's used for replacing things in the property name
      var regexOnOrHtml = /(^on-|-getHtml$)/g;
      var regexHyphens = /-/g;
      var regexDots = /\./g;

      // Go through all the property names looking for event handlers, on-*
      // Note: Use a for-in loop here over Object.keys() to get _all_ properties
      //       up the proto-chain, specifically needed when .extend()ing
      for (var propertyName in this) {

        // Just need on-* properties...
        // if ( !propertyName.startsWith('on-') ) // ES2015 (requires polyfill)
        if (propertyName.indexOf('on-') !== 0) {
          continue;
        }

        // Initialization actions will be triggered later,
        // that way the component is setup before calling the event action
        // if ( propertyName.startsWith(initEventPropName) ) // ES2015 (requires polyfill)
        if (propertyName.indexOf(initEventPropName) === 0) {
          continue;
        }

        // Convert the property name to what the event name would be
        var eventName = propertyName;
        eventName = eventName.replace(regexOnOrHtml, '');
        eventName = eventName.replace(regexHyphens, '.');

        // Special use case for the 'popups.hide.[id]' event
        // Ember usage would be 'on-popups-hide-id=(action)'
        // https://www.froala.com/wysiwyg-editor/docs/events#popups.hide.[id]
        // if ( eventName.startsWith('popups.hide.') ) // ES2016 (requires polyfill)
        if (eventName.indexOf('popups.hide.') === 0) {
          var id = eventName.replace('popups.hide.', '');
          id = id.replace(regexDots, '-'); // Convert back to hyphens
          eventName = 'popups.hide.[' + id + ']';
        }

        // Attach the appropriate event handler
        // if ( propertyName.endsWith('-getHtml') ) // ES2016 (requires polyfill)
        var getHtmlPos = propertyName.indexOf('-getHtml', -8);
        if (getHtmlPos !== -1 && getHtmlPos === propertyName.length - 8) {
          editor.events.on(eventName, Ember.run.bind(this, this.didEditorEventReturnHtml, propertyName, editor), true);
        } else {
          editor.events.on(eventName, Ember.run.bind(this, this.didEditorEvent, propertyName), true);
        }
      } // for ()


      // Get the update action and event name
      var update = this.get('update');
      var updateEvent = this.get('updateEvent');

      // If there is an update action and event,
      // then bind an event handler to get the html
      if (update && updateEvent) {
        editor.events.on(updateEvent, Ember.run.bind(this, this.didEditorEventReturnHtml, 'update', editor), true);
      }

      // Add the destroy event handler
      // Run _after_ any other destroy handler
      editor.events.on('destroy', Ember.run.bind(this, this.didDestroyEditor, editor), false);

      // Fire the "initialization" event actions (if defined)

      for (var _len = arguments.length, params = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        params[_key - 2] = arguments[_key];
      }

      if (this.get(initEventPropName)) {
        this.didEditorEvent.apply(this, [initEventPropName].concat(_toConsumableArray(params)));
      }
      if (this.get(initEventPropName + '-getHtml')) {
        this.didEditorEventReturnHtml.apply(this, [initEventPropName + '-getHtml', editor].concat(_toConsumableArray(params)));
      }
    },
    didDestroyEditor: function didDestroyEditor() {
      this.set('_editor', null);
      this.set('_editorDestroying', false);
      this.set('_editorInitialized', false);
      // No need for '_editorDestroyed', the Computed
      // Property 'editorDestroyed' fills that need
    },
    didEditorEvent: function didEditorEvent(propertyName) {
      for (var _len2 = arguments.length, params = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        params[_key2 - 1] = arguments[_key2];
      }

      if (typeof this[propertyName] === 'function') {
        return this[propertyName].apply(this, [this].concat(_toConsumableArray(params)));
      } else {
        this.sendAction.apply(this, [propertyName, this].concat(_toConsumableArray(params)));
      }
    },
    didEditorEventReturnHtml: function didEditorEventReturnHtml(propertyName, editor) {

      // Get the HTML to return as the first argument
      var html = editor.html.get();

      // SafeString in = SafeString out..
      if (this.get('returnSafeString')) {
        html = Ember.String.htmlSafe(html);
      }

      // When the action is a function, return the handlers returned value back to the editor,
      // this allows support of "canceling" a Froala Editor event.

      for (var _len3 = arguments.length, params = Array(_len3 > 2 ? _len3 - 2 : 0), _key3 = 2; _key3 < _len3; _key3++) {
        params[_key3 - 2] = arguments[_key3];
      }

      if (typeof this[propertyName] === 'function') {
        return this[propertyName].apply(this, [html, this].concat(_toConsumableArray(params)));
      } else {
        this.sendAction.apply(this, [propertyName, html, this].concat(_toConsumableArray(params)));
      }
    },
    method: function method(methodName) {
      var _this = this,
          _arguments = arguments;

      // special, added, ember-froala-editor specific method
      if (methodName === 'reinit') {
        this.reinitEditor();

        // Editor should be initialized before calling the method
      } else if (this.get('_editorInitialized')) {
        var _$;

        return (_$ = this.$(this.get('editorSelector'))).froalaEditor.apply(_$, arguments);
      } else {

        // Label for the following Promise, so it appears nicely in the Ember Inspector
        var promiseLabel = 'froala-editor: ' + ('Delayed method() call to \'' + methodName + '\' until editor is initialized');

        // Instead of throwing an error, lets return a Promise
        // that will call the method once the editor _is_ initialized
        return new Ember.RSVP.Promise(function (resolve, reject) {

          // Create a one time event listener for the initialized event
          _this.$(_this.get('editorSelector')).one(_this.get('eventPrefix') + (_this.get('_options.initOnClick') ? 'initializationDelayed' : 'initialized'), function () {

            // Try calling the Froala Editor method, returning the outcome
            try {
              var _$2;

              resolve((_$2 = _this.$(_this.get('editorSelector'))).froalaEditor.apply(_$2, _arguments));
            } catch (e) {
              reject(e);
            }
          } // () => {}
          ); // this.$().one()

        }, promiseLabel); // Ember.RSVP.Promise()

      } // else ( !editorInitialized )
    },
    // method()


    // Also define the method() as a Component action, that way it could be invoked
    // as component.send('method', 'method.name', 'arg') from an event handler
    actions: {
      method: function method() {
        this.method.apply(this, arguments);
      }
    } // :actions


  }); // Ember.Component.extend()


  FroalaEditorComponent.reopenClass({
    positionalParams: ['content', 'update', 'options']
  });

  exports.default = FroalaEditorComponent;
});