define("accounting/index", ["exports", "accounting/format", "accounting/format-column", "accounting/format-money", "accounting/format-number", "accounting/parse", "accounting/settings", "accounting/to-fixed", "accounting/unformat", "accounting/version"], function (exports, _format, _formatColumn, _formatMoney, _formatNumber, _parse, _settings, _toFixed, _unformat, _version) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.version = exports.unformat = exports.toFixed = exports.settings = exports.parse = exports.formatNumber = exports.formatMoney = exports.formatColumn = exports.format = undefined;
  exports.format = _format.default;
  exports.formatColumn = _formatColumn.default;
  exports.formatMoney = _formatMoney.default;
  exports.formatNumber = _formatNumber.default;
  exports.parse = _parse.default;
  exports.settings = _settings.default;
  exports.toFixed = _toFixed.default;
  exports.unformat = _unformat.default;
  exports.version = _version.default;
  exports.default = {
    format: _format.default,
    formatColumn: _formatColumn.default,
    formatMoney: _formatMoney.default,
    formatNumber: _formatNumber.default,
    parse: _parse.default,
    settings: _settings.default,
    toFixed: _toFixed.default,
    unformat: _unformat.default,
    version: _version.default
  };
});