define('ember-cli-simple-store/models/record-array', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.ArrayProxy.extend({
        _store: null,
        _type: null,

        content: Ember.computed(function () {
            return Ember.A(this.get("_source"));
        }),

        push: function push(data) {
            var type = this.get('_type');
            return this.get('_store').push(type, data);
        },
        remove: function remove(id) {
            var type = this.get('_type');
            this.get('_store').remove(type, id);
        }
    });
});