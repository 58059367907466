define('master-accounts/engine', ['exports', 'ember-engines/engine', 'ember-load-initializers', 'master-accounts/resolver', 'master-accounts/config/environment'], function (exports, _engine, _emberLoadInitializers, _resolver, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var modulePrefix = _environment.default.modulePrefix;


  var Eng = _engine.default.extend({
    modulePrefix: modulePrefix,
    Resolver: _resolver.default,
    dependencies: {
      services: ['router', 'session', 'api', 'i18n', 'headData'],
      externalRoutes: ['login', 'organization', 'organization.account']
    }
  });

  (0, _emberLoadInitializers.default)(Eng, modulePrefix);

  exports.default = Eng;
});