define("ember-engines/routes", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = buildRoutes;
  function buildRoutes(callback) {
    callback.isRouteMap = true;
    return callback;
  }
});