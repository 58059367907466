define('ember-cli-simple-store/models/filtered-record-array', ['exports', 'ember-cli-simple-store/models/record-array'], function (exports, _recordArray) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _recordArray.default.extend({
        content: Ember.computed(function () {
            return this.updateContent();
        }),

        updateContent: function updateContent() {
            var source = this.get("_source");
            var filter_func = this.get("_filter_func");

            return Ember.A(source.filter(filter_func));
        },
        willDestroy: function willDestroy() {
            this._unregisterRecordArray();
            this._super.apply(this, arguments);
        },
        _unregisterRecordArray: function _unregisterRecordArray() {
            var store = this.get("_store");
            store._unsubscribe(this);
        }
    });
});