define("ember-promise/mixins/promise", ["exports", "ember"], function (exports, _ember) {

    var PromiseMixin = _ember["default"].Object.create({
        xhr: function xhr(url, method, hash) {
            var self = this;
            hash = hash || {};
            hash.url = url;
            hash.method = method;
            hash = this.configureAjaxDefaults(hash);
            return new _ember["default"].RSVP.Promise(function (resolve, reject) {
                hash.success = function (json) {
                    return _ember["default"].run(null, resolve, json);
                };
                hash.error = function (json, textStatus, errorThrown) {
                    if (json && json.then) {
                        json.then = null;
                    }
                    _ember["default"].run(self, "onError", json, textStatus, errorThrown);
                    return _ember["default"].run(null, reject, json);
                };
                _ember["default"].$.ajax(hash);
            });
        },
        configureAjaxDefaults: function configureAjaxDefaults(hash) {
            hash.method = hash.method || "GET";
            hash.dataType = hash.dataType || "json";
            hash.cache = hash.cache || false;
            if (!hash.contentType && hash.data) {
                hash.contentType = "application/json";
            }
            return hash;
        },
        onError: function onError() {}
    });

    exports["default"] = PromiseMixin;
});