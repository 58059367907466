define('toggle-wrap/components/toggle-wrap', ['exports', 'toggle-wrap/templates/components/toggle-wrap'], function (exports, _toggleWrap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _toggleWrap.default,
    visible: false,
    hidden: Ember.computed.not('visible'),
    actions: {
      toggle: function toggle() {
        var visible = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

        if (visible === null) {
          visible = !this.get('visible');
        }

        this.set('visible', visible);
      }
    }
  });
});