define('ember-engines/-private/ext-require', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (moduleName) {
    var exportName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'default';

    var module = Ember.__loader.require(moduleName);

    return module[exportName];
  };
});