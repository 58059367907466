define('shared/components/task-controller', ['exports', 'shared/templates/components/task-controller'], function (exports, _taskController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _taskController.default,
    runTaskOnInit: false,
    isSave: false,
    retry: false,
    showWhenLoading: true,
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('runTaskOnInit')) {
        this.get('task').perform();
      }
    },

    isRunningDidChange: Ember.observer('task.isRunning', function () {
      this.set('retry', false);
    }),
    showLoading: Ember.computed.and('task.isRunning', 'showWhenLoading'),
    showMask: Ember.computed.or('task.isRunning', 'showError'),
    showError: Ember.computed('task.last.error', 'retry', function () {
      return this.get('task.last.error') && !this.get('retry');
    })
  });
});