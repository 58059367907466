enifed('ember-debug/handlers', ['exports'], function (exports) {
  'use strict';

  var HANDLERS = exports.HANDLERS = {};

  var registerHandler = function () {};
  var invoke = function () {};

  exports.registerHandler = registerHandler = function (type, callback) {
    var nextHandler = HANDLERS[type] || function () {};

    HANDLERS[type] = function (message, options) {
      callback(message, options, nextHandler);
    };
  };

  exports.invoke = invoke = function (type, message, test, options) {
    if (test) {
      return;
    }

    var handlerForType = HANDLERS[type];

    if (handlerForType) {
      handlerForType(message, options);
    }
  };


  exports.registerHandler = registerHandler;
  exports.invoke = invoke;
});