define('shared/components/ico-moon', ['exports', 'shared/templates/components/ico-moon'], function (exports, _icoMoon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _icoMoon.default,
    tagName: 'svg',
    classNameBindings: 'iconClasses',
    iconClasses: Ember.computed('icon', 'notFound', function () {
      return 'icon icon--' + this.get('icon') + ' ' + (this.get('notFound') ? 'u-c-orange4' : '');
    }),
    icon: Ember.computed('name', 'notFound', function () {
      if (this.get('notFound')) {
        return 'question2';
      }

      return this.get('name');
    }),
    namdDidChange: Ember.observer('name', function () {
      this.set('notFound', false);

      if (this.get('loaded')) {
        this.checkIfFound(true);
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.$()[0].addEventListener('load', function () {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        _this.checkIfFound(true);

        _this.set('loaded', true);
      }, true);
    },
    checkIfFound: function checkIfFound() {
      var _this2 = this;

      var scheduleRecheck = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      Ember.run.later(function () {
        if (_this2.isDestroyed || _this2.isDestroying) {
          return;
        }

        if (_this2.$().is(':hidden')) {
          return;
        }

        if (!_this2.$('use')[0].getBoundingClientRect().width) {
          // we recheck after 1 second incase something happened since
          // our checks are fairly hacky... should be better when we move
          // all the svg into index.html or if we only do this in dev
          if (scheduleRecheck) {
            var recheck = function recheck() {
              _this2.set('notFound', false);
              _this2.checkIfFound();
            };

            Ember.run.next(recheck);
            Ember.run.later(recheck, 1000);
          }

          _this2.set('notFound', true);
        }
      });
    }
  }).reopenClass({
    positionalParams: ['name']
  });
});