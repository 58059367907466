define('blaze-components/components/blaze-field', ['exports', 'blaze-components/templates/components/blaze-field'], function (exports, _blazeField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    layout: _blazeField.default,
    useErrorText: true,
    showError: Ember.computed.and('useErrorText', 'error'),
    isSelect: Ember.computed.equal('type', 'select'),
    isTextarea: Ember.computed.equal('type', 'textarea'),
    isCheckbox: Ember.computed.equal('type', 'checkbox'),
    isRadio: Ember.computed.equal('type', 'radio'),
    isInputList: Ember.computed.equal('type', 'input-list'),
    fieldDisabled: Ember.computed.or('form.disabled', 'disabled', 'loading'),
    inputList: [],
    valueChanged: function valueChanged() {},
    init: function init() {
      this._super.apply(this, arguments);

      this.valueToInputList();
    },

    inputListDidChange: Ember.on('init', Ember.observer('inputList', 'inputList.@each.value', function () {
      var inputs = this.get('inputList');

      if (!inputs.length || inputs[inputs.length - 1].value && (!this.get('max') || inputs.length < this.get('max'))) {
        this.get('inputList').pushObject({ value: '' });
      }
    })),
    errorClass: Ember.computed('error', function () {
      if (this.get('error')) {
        return 'c-field--error';
      }

      return '';
    }),
    nameDidChange: Ember.on('init', Ember.observer('name', function () {
      this.addObserver('form.errors.' + this.get('name'), function () {
        this.set('error', this.get('form.errors.' + this.get('name')));
      });
    })),
    valueDidChange: Ember.observer('value', function () {
      if (this.get('form.errors.' + this.get('name'))) {
        this.set('form.errors.' + this.get('name'), false);
      }

      if (!this.get('internalValueUpdate')) {
        this.valueToInputList();
      }

      this.set('internalValueUpdate', false);
    }),
    inputOptions: Ember.computed('options.length', 'value', function () {
      var _this = this;

      var options = this.getWithDefault('options', []);

      return options.map(function (option) {
        if (typeof option === 'string') {
          option = { value: option };
        }

        option = {
          value: option.disabled ? '' : option.value,
          text: option.text || option.value,
          icon: option.icon,
          help: option.help,
          prefix: option.indent ? '&nbsp'.repeat(4 * option.indent) : '',
          disabled: option.disabled
        };

        var value = _this.getWithDefault('value', '');
        if (value !== true && value !== false) {
          value = (value || '').toString();
        }

        option.selected = !option.disabled && (option.value === value || option.value.toString() === value);

        return option;
      });
    }),
    valueToInputList: function valueToInputList() {
      if (this.get('isInputList')) {
        var inputs = this.getWithDefault('value', []).map(function (value) {
          return { value: value };
        });

        this.set('inputList', inputs);
      }
    },

    actions: {
      updateInput: function updateInput(input, event) {
        Ember.set(input, 'error', false);
        Ember.set(input, 'errorClass', '');
        Ember.set(input, 'errorPhrase', '');

        if (this.attrs.inputListValidate && !this.attrs.inputListValidate(input, event.target.value)) {
          return;
        }

        Ember.set(input, 'value', event.target.value);

        this.set('internalValueUpdate', true);
        this.set('value', this.get('inputList').map(function (i) {
          return i.value;
        }).filter(function (v) {
          return v;
        }).uniq());

        this.get('valueChanged')(this.get('value'), event);
      },
      updateValue: function updateValue(event) {
        var value = (typeof event === 'undefined' ? 'undefined' : _typeof(event)) === 'object' ? event.target.value : event;

        if (value === 'true') {
          value = true;
        }

        if (value === 'false') {
          value = false;
        }

        this.set('value', value);

        this.get('valueChanged')(this.get('value'), event);
      },
      toggleValue: function toggleValue(event) {
        this.set('value', !!(event.target.checked || event.target.selected));

        this.get('valueChanged')(this.get('value'), event);
      }
    }
  }).reopenClass({
    positionalParams: ['form']
  });
});